import React from 'react';
import { AdminLayout } from '../layouts';
import { adminRoutes } from './Admin';


export const routes = () => {
  return [
    {
      element: <AdminLayout />,
      children: [...adminRoutes()],
    },
    // {
    //   path: "*",
    //   element: <NotFound />,
    // },
  ];
};