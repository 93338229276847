import toastr from "toastr";

const modalNotification = ({ type, message, closeBtn =false }) => {
  console.log("type", type)
  let icon = {
    info: "icon-info-fill",
    success: "icon-check-circle-fill",
    error: "icon-cross-circle-fill",
    warning: "icon-alert-fill",
  };

  let msg = `<span class="toast-icon"><em class="icon ${icon[type]}"></em></span><div class="toast-text">${message}</div>`;
  toastr.options = {
    closeButton: closeBtn,
    debug: false,
    newestOnTop: false,
    progressBar: false,
    positionClass: "toast-top-right",
    closeHtml: '<span class="btn-trigger">&#10006;</span>',
    preventDuplicates: true,
    showDuration: "1500",
    hideDuration: "1500",
    timeOut: "3000",
    toastClass: "toast",
    extendedTimeOut: "3000",
  };
  // eslint-disable-next-line
  toastr[type](msg);
};

export default modalNotification;
