export const setTokenInLocalStorage = (token) => {
    localStorage.setItem('token', token);
};

export const getToken = () => {
    return localStorage.getItem('token');
};

export const setUserInLocalStorage = (user) => {
    localStorage.setItem('user', JSON.stringify(user));
};

export const getUser = () => {
    return JSON.parse(localStorage.getItem('user'));
};

export const clearAuth = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
};
