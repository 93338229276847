import adminRouteMap from "./adminRouteMap";
import { ActiveOrders, AdminDashboard, AddBlog, EditBlog, Blogs, BlogDetails, CancelledOrders, CompleteOrders, Customers, FormElement, PrivacyPolicy, Seller, TermsConditions, UsreDetails } from "../../pages";

export default function route() {
  return [
    {
      path: adminRouteMap.DASHBOARD.path,
      name: "Dashboard",
      element: <AdminDashboard />,
    },
    {
      path: adminRouteMap.CUSTOMERS.path,
      name: "Users",
      element: <Customers />,
    },
    {
      path: adminRouteMap.BLOGS.path,
      name: "Blogs",
      element: <Blogs />,
    },
    {
      path: `${adminRouteMap.BLOGDETAILS.path}/:blogID`,
      name: "Blog Details",
      element: <BlogDetails />,
    },
    {
      path: adminRouteMap.ADDBLOG.path,
      name: "Add Blog",
      element: <AddBlog />,
    },
    {
      path: `${adminRouteMap.EDITBLOG.path}/:blogID`,
      name: "Edit Blog",
      element: <EditBlog />,
    },
    {
      path: adminRouteMap.SELLER.path,
      name: "Users",
      element: <Seller />,
    },
    {
      path: adminRouteMap.ACTIVEORDERS.path,
      name: "Active Orders",
      element: <ActiveOrders />,
    },
    {
      path: adminRouteMap.COMPLETEDORDERS.path,
      name: "Completed Orders",
      element: <CompleteOrders />,
    },
    {
      path: adminRouteMap.CANCELLEDORDERS.path,
      name: "Cancelled Orders",
      element: <CancelledOrders />,
    },
    {
      path: adminRouteMap.USERDETAILS.path,
      name: "User Details",
      element: <UsreDetails />,
    },
    {
      path: adminRouteMap.FORMELEMENT.path,
      name: "Form Element",
      element: <FormElement />,
    },
    {
      path: adminRouteMap.PRIVACYPOLICY.path,
      name: "Privacy Policy",
      element: <PrivacyPolicy />,
    },
    {
      path: adminRouteMap.TERMSANDCONDITIONS.path,
      name: "Terms and COnditions",
      element: <TermsConditions />,
    },
    {
      path: adminRouteMap.BLOGS.path,
      name: "Blogs",
      element:<Blogs />,
    },
  ];
}
