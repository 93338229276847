import { AdminPublicLayout } from "../../layouts";
import publicRoutes from "./public.route";
import privateRoutes from "./private.route";
import PrivateRoute from "../../components/PrivateRoute";

export const adminRoutes = () => {
  return [
    {
      element: <AdminPublicLayout />,
      children: [...publicRoutes()],
    },
    {
      element: <PrivateRoute/>,
      children: [...privateRoutes()],
    },
  ];
};
