
const adminRouteMap = {
  LOGIN: { path: `${baseRoutes.adminBaseRoutes}/`},
  DASHBOARD: { path: `${baseRoutes.adminBaseRoutes}/dashboard`},
  FORGOTPASSWORD: { path: `${baseRoutes.adminBaseRoutes}/forgot-password`},
  CUSTOMERS: { path: `${baseRoutes.adminBaseRoutes}/customers`},
  SELLER: { path: `${baseRoutes.adminBaseRoutes}/seller`},
  ACTIVEORDERS: { path: `${baseRoutes.adminBaseRoutes}/active-orders`},
  COMPLETEDORDERS: { path: `${baseRoutes.adminBaseRoutes}/completed-orders`},
  CANCELLEDORDERS: { path: `${baseRoutes.adminBaseRoutes}/cancelled-orders`},
  USERDETAILS: { path: `${baseRoutes.adminBaseRoutes}/user-details`},
  FORMELEMENT: { path: `${baseRoutes.adminBaseRoutes}/form-element`},
  PRIVACYPOLICY: { path: `${baseRoutes.adminBaseRoutes}/privacy-policy`},
  TERMSANDCONDITIONS: { path: `${baseRoutes.adminBaseRoutes}/terms-and-condition`},
  RESETPASSWORD: { path: `${baseRoutes.adminBaseRoutes}/reset-password`},
  BLOGS: { path: `${baseRoutes.adminBaseRoutes}/blogs`},
  LOGIN: { path: `/` },
  DASHBOARD: { path: `/dashboard` },
  FORGOTPASSWORD: { path: `/forgot-password` },
  RESETPASSWORD: { path: `/reset-password` },
  CUSTOMERS: { path: `/customers` },
  BLOGS: { path: `/blogs` },
  BLOGDETAILS: { path: `/blog-details` },
  ADDBLOG: { path: `/add-blog` },
  EDITBLOG: { path: `/edit-blog` },
  SELLER: { path: `/seller` },
  ACTIVEORDERS: { path: `/active-orders` },
  COMPLETEDORDERS: { path: `/completed-orders` },
  CANCELLEDORDERS: { path: `/cancelled-orders` },
  USERDETAILS: { path: `/user-details` },
  FORMELEMENT: { path: `/form-element` },
  PRIVACYPOLICY: { path: `/privacy-policy` },
  TERMSANDCONDITIONS: { path: `/terms-and-condition` },
};
export default adminRouteMap;