import { 
  // Input as AntInput, 
  Form } from "antd";
// import { useField } from "formik";

function Password({
  toggleIcon,
  icon,
  setFieldValue,
  wrapperFn,
  type = "password",
  ...rest
}) {
  // const [field, meta] = useField(name);
  // const config = { ...field, ...rest };

  // if (meta && meta.touched && meta.error) {
  //   config.error = true;
  //   config.helperText = meta.error;
  // }

  // if (wrapperFn) {
  //   return wrapperFn(
  //     <AntInput {...field} {...rest} placeholder="Basic usage" />
  //   );
  // }
  return (
    <Form.Item className="mb-0"
      // help={meta.error && meta?.error && meta?.touched ? meta.error : ""}
      // validateStatus={config.error ? "error" : "success"}
    >
      {toggleIcon && toggleIcon}
      {icon && icon}
      <input type={type} 
      // {...field} 
      {...rest} />
      <span>Text</span>
    </Form.Item>
  );
}

export default Password;
