import { Form } from "antd";
// import { useField } from "formik";

function AntTextArea({ Children, ...rest }) {
  // const [field, meta] = useField(name);
  // const config = { ...field, ...rest };

  // if (meta && meta.touched && meta.error) {
  //   config.error = true;
  //   config.helperText = meta.error;
  // }

  return (
    <Form.Item className="mb-0"
      // help={meta.error && meta?.error && meta?.touched ? meta.error : ""}
      // validateStatus={config.error ? "error" : "success"}
    >
      <textarea 
      // {...field} 
      {...rest}>
        {Children}
      </textarea>
    </Form.Item>
  );
}

export default AntTextArea;
