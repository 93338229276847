// import loadable from "@loadable/component";

export {default as Input} from './Input/index.ant';
export {default as Form} from './Form/index.ant';
export {default as Table} from './Table/index.ant';
export {default as Password} from './Password/index.ant';
export {default as AntTextArea} from './TextArea/index.ant';
export {default as Rate} from './Rate/index.ant';
export {default as Checkbox} from './Checkbox/index.ant';
export {default as AntRadio} from './Radio/index.ant';
export {default as Popovers} from './Popover/index.ant';
export {default as DatePicker} from './DatePicker/index.ant';
export {default as TimePicker} from './TimePicker/index.ant';
export {default as Switch} from './Switch/index.ant';
export {default as AntTooltip} from './Tooltip/index.ant';
// export {default as Upload} from './Upload/index.ant';
export {default as Select} from './Select/index.ant';
// export {default as MultiUpload} from './MultiUpload/index.ant';
export {default as CheckboxGroup} from './CheckboxGroup/index.ant';
export {default as RangeSlider} from './RangeSlider/index.ant';



// export const Input = loadable(() => import("./Input/index.ant"));
// export const Form = loadable(() => import("./Form/index.ant"));
// export const Table = loadable(() => import("./Table/index.ant"));
// export const Password = loadable(() => import("./Password/index.ant"));
// export const AntTextArea = loadable(() => import("./TextArea/index.ant"));
// export const Rate = loadable(() => import("./Rate/index.ant"));
// export const Checkbox = loadable(() => import("./Checkbox/index.ant"));
// export const AntRadio = loadable(() => import("./Radio/index.ant"));
// export const Popovers = loadable(() => import("./Popover/index.ant"));
// export const DatePicker = loadable(() => import("./DatePicker/index.ant"));
// export const Switch = loadable(() => import("./Switch/index.ant"));
// export const AntTooltip = loadable(() => import("./Tooltip/index.ant"));
// export const Upload = loadable(() => import("./Upload/index.ant"));
// export const Select = loadable(() => import("./Select/index.ant"));
// export const MultiUpload = loadable(() => import("./MultiUpload/index.ant"));
// export const CheckboxGroup = loadable(() => import("./CheckboxGroup/index.ant"));
// export const RangeSlider = loadable(() => import("./RangeSlider/index.ant"));