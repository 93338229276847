import { post } from "./apiHelpers";

const getCoverURL = async (key, contentType) => {
    try {
        const response = await post("/coverURL", {
            Key: key,
            ContentType: contentType,
        })
        if (!response?.data?.success) {
            throw new Error('Failed to get signed URL');
        }

        return response?.data?.signedURL;
    } catch (error) {
        console.error('Error getting signed URL:', error);
        throw error;
    }
}

const generateUniqueFileName = (originalName) => {
    const timestamp = new Date().getTime();
    const randomString = Math.random().toString(36).substring(2, 8);
    const extension = originalName.split('.').pop();
    return `${timestamp}-${randomString}.${extension}`;
};


export const uploadFile = async (file) => {
    const uniqueFileName = generateUniqueFileName(file.name);
    const signedURL = await getCoverURL(uniqueFileName, file.type);
    try {
        if (!signedURL) {
            throw new Error('Signed URL is required!');
        }
        const response = await fetch(signedURL, {
            method: 'PUT',
            headers: {
                'Content-Type': file.type
            },
            body: file,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return uniqueFileName;
    } catch (error) {
        console.error('Error uploading file:', error);
        throw error;
    }
}