import React, { useState } from 'react';
import { Input as TextInput, Switch } from '../../Antd';
import { Accordion } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ModalComponent from '../../Modal';
import ImageElement from '../../ImageElement';
import adminRouteMap from '../../../routes/Admin/adminRouteMap';

function AdmindetailsSidebar({closeSidebar, extraClassName=''}) {

    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);

    const [showModal, setShowModal] = useState(false);

    const handleCloseModal = () => {
        setShowModal(false);
    }

    const strengthLevels = [
        { level: 0, label: 'Weak', color: '#dc3545' },
        { level: 8, label: 'Medium', color: '#ffc107' },
        { level: 12, label: 'Strong', color: '#28a745' },
    ]
    return (
        <>
            <div className={`profileSidebar ${extraClassName}`}>
                <Link to='#' onClick={closeSidebar} className="closeSidebar">
                    <em className="icon icon-cross"/>
                </Link>

                <div className="profileSidebar_imgsec position-relative">
                    <div className="coverImg">
                        <ImageElement source="profile-cover.png" className='img-fluid' alt="cover-img"/>
                    </div>
                    <div className="mainImg uploadImg position-absolute">
                        <ImageElement source="avatar/avatar-3.jpg" className='img-fluid' alt="cover-img"/>
                    </div>
                </div>

                <div className="profileSidebar_cnt">
                    <div className="profileDetail text-center">
                        <h3>Robert Fox</h3>
                        <h4 className='mb-0'>robert.fox@velzon.com</h4>
                        <Link to='#' onClick={() => setShowModal(true)} className='btn btn-sm btn-light d-inline-block'>Edit Profile</Link>
                    </div>
                </div>
            </div>

            <ModalComponent 
                modalHeader='Edit Profile'
                size='md'
                show={showModal}
                onHandleCancel={handleCloseModal}
            >
                <div>
                    <form>
                        <div className='form-group'>
                            <div className="mainImg uploadImg position-relative">
                                <ImageElement source="avatar/avatar-3.jpg" className='img-fluid' alt="cover-img"/>
                                <label for="upload-img1">
                                    <input type="file" id="upload-img1" />
                                    <em className="icon icon-camera-fill" />
                                </label>
                            </div>
                        </div>
                        <div className='form-group'>
                            <label className='form-label'>Name</label>
                            <TextInput 
                                name='name'
                                type='text'
                                className='form-control'
                                placeholder='Enter name'
                                defaultValue='Anna Adame'
                            />
                        </div>
                        <div className='form-group'>
                            <label className='form-label'>Email</label>
                            <TextInput 
                                name='name'
                                type='text'
                                className='form-control'
                                placeholder='Enter email'
                                value='daveadame@velzon.com'
                                readonly
                                icon={
                                    <div className='fieldicon-right'>
                                        <em className='icon icon-lock'/>
                                    </div>
                                }
                            />
                        </div>
                        <div className='d-flex align-items-center justify-content-center'>
                            <button className='btn btn-md btn-light me-4' onClick={handleCloseModal}>Cancel</button>
                            <button className='btn btn-primary'>Save</button>
                        </div>
                    </form>
                </div>
            </ModalComponent>
        </>
    )
}

export default AdmindetailsSidebar;