import { useContext, createContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import { clearAuth, getToken, setTokenInLocalStorage, setUserInLocalStorage } from "../utils/auth";
import { post } from "../utils/apiHelpers";
import adminRouteMap from "../routes/Admin/adminRouteMap";
import { modalNotification } from "../components";
const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [token, setToken] = useState(getToken());
    const navigate = useNavigate();

    const loginAction = async (payload) => {
        try {
            const res = await post("/users/super-admin/login", payload);
            if (res.data.success) {
                setToken(res.data.token);
                setUser(res.data.user);
                setTokenInLocalStorage(res.data.token);
                setUserInLocalStorage(res.data.user);
                navigate(adminRouteMap.DASHBOARD.path);
            }
        } catch (error) {
            console.error('Login error:', error);
            modalNotification({ type: 'error', message: error?.response?.data?.message || error?.response?.data?.error });
        }
    };

    const logOut = () => {
        clearAuth();
        setToken("");
        setUser(null);
        navigate(adminRouteMap.LOGIN.path);
    };

    const resetPassword = async(payload) => {
        try {
            const res = await post("/users/generate-reset-password-link", payload);
            console.log("res", res);
            navigate(adminRouteMap.LOGIN.path);
        }   catch (error) {
            console.error('Reset Password:', error);
            modalNotification({ type: 'error', message: error?.response?.data?.message || error?.response?.data?.error });
        }
    }

    return <AuthContext.Provider value={{ token, user, loginAction, logOut,resetPassword }}>{children}</AuthContext.Provider>;
};

export default AuthProvider;

export const useAuth = () => {
    return useContext(AuthContext);
};