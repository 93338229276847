import React from "react";

function AdminFooter() {
  const year = new Date().getFullYear();
  return (
    <>
      <footer className="footer">
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-6">
                    {year} &copy; qahwah.
                </div>
            </div>
        </div>
      </footer>
    </>
  );
}

export default AdminFooter;
