import { routes } from './routes';
import { useRoutes } from 'react-router-dom';
import './App.scss';
import { Suspense } from 'react';
import { Spinner } from 'react-bootstrap';
import { ImageElement } from './components';
import AuthProvider from './contexts/AuthProvider';

function App() {
  function RouteLayout({ path }) {
    const element = useRoutes(path);
    return element;
  }
  return (
    <>
      <Suspense fallback={
        <div className="d-flex align-items-center justify-content-center mainLoader">
          <div className="mainLoader_holder">
            <ImageElement source="logo-icon.svg" alt="logo-loader" />
            <div className="loader-inner"></div>
          </div>
        </div>
      }>
        <AuthProvider>
          <RouteLayout path={routes()} />
        </AuthProvider>
      </Suspense>
    </>
  );
}

export default App;
