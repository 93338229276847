import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../contexts/AuthProvider";
import adminRouteMap from "../routes/Admin/adminRouteMap";
import { AdminPrivateLayout } from "../layouts";

const PrivateRoute = () => {
  const user = useAuth();
  if (!user.token) return <Navigate to={adminRouteMap.LOGIN.path} />;
  return <AdminPrivateLayout />;
};

export default PrivateRoute;