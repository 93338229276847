import React from "react";

export const AdminLogin = React.lazy(() => import("./Login/index.page"));
export const ForgotPassword = React.lazy(() => import("./ForgotPassword/index.page"));
export const AdminDashboard = React.lazy(() => import("./Dashboard/index.page"));
export const Customers = React.lazy(() => import("./Customers/index.page"));
export const BlogDetails = React.lazy(() => import("./Blogs/BlogDetails/index.page"));
export const Seller = React.lazy(() => import("./Seller/index.page"));
export const UsreDetails = React.lazy(() => import("./UserDetails/index.page"));
export const FormElement = React.lazy(() => import("./FormElement/index.page"));
export const ResetPassword = React.lazy(() => import("./ResetPassword/index.page"));
export const Blogs = React.lazy(() => import("./Blogs/index.page"));
export const AddBlog = React.lazy(() => import("./Blogs/AddBlog/index.page"));
export const EditBlog = React.lazy(() => import("./Blogs/EditBlog/index.page"));

export * from './ManageOrders';
export * from './ManageCms';
