export const validateName = (name) => {
  if (!name) {
    return "Please enter your full name";
  }
  return null;
};

export const validateEmail = (email) => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!email) {
    return "Please enter your email";
  } else if (!emailPattern.test(email)) {
    return "Invalid email format";
  }
  return null;
};

export const validatePassword = (password) => {
  if (!password) {
    return "Please enter your password";
  }
  return null;
};

export const validateConfirmPassword = (password, confirmPassword) => {
  if (!confirmPassword) {
    return "Please confirm your password";
  } else if (password !== confirmPassword) {
    return "Passwords do not match";
  }
  return null;
};

export const validateMobile = (mobile) => {
  const phonePattern = /^[0-9]+$/;
  if (mobile && !phonePattern.test(mobile)) {
    return "Phone number should contain only numbers";
  }
  return null;
};

export const validateSignupForm = (formData) => {
  const errors = {};

  errors.name = validateName(formData.name);
  errors.email = validateEmail(formData.email);
  errors.password = validatePassword(formData.password);
  errors.confirmPassword = validateConfirmPassword(formData.password, formData.confirmPassword);

  return errors;
};
export const validateLoginForm = (formData) => {
  const errors = {};

  errors.email = validateEmail(formData.email);
  errors.password = validatePassword(formData.password);

  return errors;
};
