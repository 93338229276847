import adminRouteMap from "./adminRouteMap";
import { AdminLogin, ForgotPassword } from "../../pages";
import ResetPassword from "../../pages/Admin/ResetPassword/index.page";

export default function route() {
  return [
    {
      path: adminRouteMap.LOGIN.path,
      name: "Admin Login",
      element: <AdminLogin />,
    },
    {
      path: adminRouteMap.FORGOTPASSWORD.path,
      name: "Forgot Password",
      element: <ForgotPassword />,
    },
    {
      path: adminRouteMap.RESETPASSWORD.path,
      name: "Reset Password",
      element: <ResetPassword />,
    },
  ];
}
