import React from 'react';
import { Link } from 'react-router-dom';

function FilterSidebar({isOpen, setIsOpen, children}) {
    const closeSidebar = () => {
        setIsOpen(false);
    };
  return (
    <div className={`filterSidebar position-fixed ${isOpen ? 'open' : ''}`}>
        <div className='filterSidebar_head'>
            <h3>Filter</h3>
            <Link to='#' onClick={closeSidebar}> <em className='icon icon-cross'/> </Link>
        </div>
        {children}
    </div>
  )
}

export default FilterSidebar;